@font-face {
  font-family: 'Bebas Neue';
  src: url('/fonts/BebasNeue-Thin.woff2') format('woff2'),
    url('/fonts/BebasNeue-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('/fonts/BebasNeue-Regular.woff2') format('woff2'),
    url('/fonts/BebasNeue-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('/fonts/BebasNeue-Light.woff2') format('woff2'),
    url('/fonts/BebasNeue-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue Book';
  src: url('/fonts/BebasNeue-Book.woff2') format('woff2'),
    url('/fonts/BebasNeue-Book.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('/fonts/BebasNeue-Bold.woff2') format('woff2'),
    url('/fonts/BebasNeue-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Eina 03';
  src: url('/fonts/Eina03-Bold.woff2') format('woff2'),
    url('/fonts/Eina03-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('/fonts/BebasNeue-Bold.woff2') format('woff2'),
    url('/fonts/BebasNeue-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Eina 03';
  src: url('/fonts/Eina03-Light.woff2') format('woff2'),
    url('/fonts/Eina03-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Eina 03 RegularItalic';
  src: url('/fonts/Eina03-RegularItalic.woff2') format('woff2'),
    url('/fonts/Eina03-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('/fonts/BebasNeue-Regular.woff2') format('woff2'),
    url('/fonts/BebasNeue-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('/fonts/BebasNeue-Light.woff2') format('woff2'),
    url('/fonts/BebasNeue-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Eina 03';
  src: url('/fonts/Eina03-LightItalic.woff2') format('woff2'),
    url('/fonts/Eina03-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Bebas Neue Book';
  src: url('/fonts/BebasNeue-Book.woff2') format('woff2'),
    url('/fonts/BebasNeue-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Eina 03';
  src: url('/fonts/Eina03-Regular.woff2') format('woff2'),
    url('/fonts/Eina03-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Eina 03';
  src: url('/fonts/Eina03-SemiBold.woff2') format('woff2'),
    url('/fonts/Eina03-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('/fonts/BebasNeue-Thin.woff2') format('woff2'),
    url('/fonts/BebasNeue-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Eina 03';
  src: url('/fonts/Eina03-SemiboldItalic.woff2') format('woff2'),
    url('/fonts/Eina03-SemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Eina 03';
  src: url('/fonts/Eina03-BoldItalic.woff2') format('woff2'),
    url('/fonts/Eina03-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

body {
  font-family: 'Eina 03', sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Bebas Neue', sans-serif;
  text-transform: uppercase;
}
